import { Button } from "@9amhealth/shared";
import { useBloc as useBloc2 } from "@blac/react";
import styled from "@emotion/styled";
import { DisableAppPadding } from "components/AppRouter/AppRouter";
import Translate from "components/Translate/Translate";
import React, { useCallback, useEffect } from "react";
import { APP_BREAKPOINT, APP_CONTENT_WIDTH } from "src/constants/layout";
import OnboardingBloc from "src/state/OnboardingBloc/OnboardingBloc";
import TaskManagementBloc from "src/state/TaskManagementBloc/TaskManagementBloc";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { tracker, useBloc } from "src/state/state";
import StepList from "src/ui/components/StepList/StepList";
import Loader from "../Loader/Loader";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { InfoCircleIcon } from "src/constants/icons";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";

const OnboardingPageWrap = styled.div`
  label: OnboardingPageWrap;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: calc(
    var(--ion-safe-area-bottom, 0) + var(--navbar-padding-bottom, 55px) + 1rem
  );
  padding-top: calc(var(--ion-safe-area-top, 0) + 2rem);
  overflow: auto;

  > div {
    height: 100%;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    > div {
      height: auto;
    }
  }
`;

const OnboardingStepsWrapper = styled.div`
  label: OnboardingStepsWrapper;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  gap: 2rem;
  justify-content: space-between;
  max-width: ${APP_CONTENT_WIDTH}px;
  padding: 0 1.5rem 1rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    overflow: hidden;
    border-radius: 0.5rem;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(12px);
    height: auto;
    padding: 2.5rem 1.5rem;
    margin: 5rem 0 0;
  }
`;

const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
`;

const StyledPill = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 2.75rem;
  border-radius: 7.5rem;
  background: rgba(255, 255, 255, 0.32);
  backdrop-filter: blur(8px);
  padding: 0.375rem 1rem;
  margin-bottom: 1.25rem;

  small {
    font-weight: 600;
  }
`;

const IconWrap = styled.div`
  margin-left: 0.375rem;
  margin-top: 0.125rem;
`;

const OnboardingTitle = styled.h2`
  font-size: 2.25rem;
  margin-bottom: 0.75rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    font-size: 2.5rem;
  }
`;

const OnboardingDescription = styled.p`
  font-size: 1.125rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    font-size: 1.25rem;
  }
`;

const StepListWrapper = styled.div``;

const TopSection = styled.div``;
const BottomSection = styled.div``;

const OnboardingSteps = () => {
  const [
    { loading, ctaText, steps, title, backgroundImage, variables },
    { handleCtaPress, updateSteps, initialSyncVisitEnabled }
  ] = useBloc2(OnboardingBloc);
  const [tasks, { loadProgramTasks }] = useBloc(TaskManagementBloc);

  const [, { firstName }] = useBloc(UserPreferencesCubit);

  const updateOnboardingTasks = useCallback(() => {
    void loadProgramTasks(KnownProgram.ONBOARDING);
  }, []);

  useEffect(() => {
    updateSteps();
  }, [tasks]);

  useEffect(() => {
    document.addEventListener("popupClosed", updateOnboardingTasks, {
      passive: true
    });

    return () => {
      document.removeEventListener("popupClosed", updateOnboardingTasks);
    };
  }, []);

  const titles = steps.map((step) => step.title);
  const subtitles = steps.map((step) => step.subtitle ?? "");

  const statusAccountSetup = steps[1]?.status;
  const statusGetYourLabsDone = steps[2]?.status;
  const statusScheduleFirstVisit = steps[3]?.status;

  useEffect(() => {
    tracker.track("Onboarding Steps View Changed", {
      data: {
        title,
        stepTitles: titles,
        stepSubtitles: subtitles,
        ctaText,
        statusAccountSetup,
        statusGetYourLabsDone,
        statusScheduleFirstVisit,
        initialSyncVisitEnabled
      }
    });
  }, [
    titles.join(""),
    subtitles.join(""),
    ctaText,
    statusAccountSetup,
    statusGetYourLabsDone,
    statusScheduleFirstVisit,
    initialSyncVisitEnabled
  ]);

  if (loading) {
    return <Loader active fixed />;
  }

  const handleClick = () => {
    AppQueryPopupsController.openPopup(AppPopup.faq);
  };

  return (
    <OnboardingPageWrap>
      <div>
        <DisableAppPadding />
        <BackgroundImage src={backgroundImage} />

        <OnboardingStepsWrapper>
          <TopSection>
            <StyledPill onClick={handleClick}>
              <small>
                <Translate msg="benefit.onboarding.title" />
              </small>
              <IconWrap>
                <InfoCircleIcon />
              </IconWrap>
            </StyledPill>

            <OnboardingTitle>
              {title && <Translate msg={title} variables={{ firstName }} />}
            </OnboardingTitle>

            <OnboardingDescription>
              <Translate msg="onboardingSteps.description" />
            </OnboardingDescription>

            <StepListWrapper>
              <StepList steps={steps} variables={variables} />
            </StepListWrapper>
          </TopSection>
          {ctaText && (
            <BottomSection>
              <Button
                theme="dusk"
                style={{ width: "100%" }}
                onPress={() => {
                  handleCtaPress();
                }}
              >
                <Translate msg={ctaText} />
              </Button>
            </BottomSection>
          )}
        </OnboardingStepsWrapper>
      </div>
    </OnboardingPageWrap>
  );
};

export default OnboardingSteps;
